type MingleInputProps = {
  percentage?: number;
  label?: string;
};

export const ProgressUpDown = ({ percentage, label }: MingleInputProps) => {
  return (
    <div
      className={`flex flex-row justify-center items-center font-bold py-2 px-3 rounded-full w-18 gap-1 bg-gray-100 dark:bg-gray-800 min-w[48px]`}
    >
      {percentage < 0 || label === "down" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 11 9"
          className="w-3 h-3"
        >
          <path
            fill="#EF5944"
            d="M6.219 8.144a1 1 0 0 1-1.696 0L.699 2.03A1 1 0 0 1 1.547.5h7.648a1 1 0 0 1 .848 1.53L6.219 8.144Z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 11 9"
          className="w-3 h-3"
        >
          <path
            fill="#35B295"
            d="M4.523.856a1 1 0 0 1 1.696 0l3.824 6.114a1 1 0 0 1-.848 1.53H1.547a1 1 0 0 1-.848-1.53L4.523.856Z"
          />
        </svg>
      )}
      <div className="h-5">
        {percentage || percentage === 0 ? <>{percentage} %</> : <> {label}</>}
      </div>
    </div>
  );
};
